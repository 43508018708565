@import '../../variables';

.header-container {
    display: flex;
    width: 100vw;
    height: $headerHeight;
    background-color: $colorLightBlue;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    padding: $xsmallMargin $xxlMargin;
    align-items: center;

    .dropdown-container {
        margin-right: $bigMargin;
        width: $folderDropdownWidth;
        height: $dropdownHeight;

        &:nth-of-type(2) {
            width: $fileDropdownWidth;
        }
    }

    .scores-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 65px;
        background-color: white;
        border-radius: 5px;
        margin-left: auto;
        padding: 8px $smallMargin;
        font-size: 13px;
        min-width: 170px;

        span {
            font-weight: bold;
        };
    }

    .buttons-container {
        margin-right: 0;

        a {
            margin-left: $mediumMargin;
            text-decoration: none;
        }
    }
}