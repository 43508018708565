@import '../../variables';

.sidebar-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
    width: $sidebarWidth;
    height: calc(100% - #{$headerHeight});
    background-color: $colorLightGrey;
    margin-top: $headerHeight;
    padding: $xsmallMargin 0 $xsmallMargin $xsmallMargin;
    overflow-y: auto;

    .courier-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        margin: $xsmallMargin 0 $smallMargin 0;
        padding: $xsmallMargin $mediumMargin $xsmallMargin $xsmallMargin;

        input[type='checkbox'] {
            position: relative;
            margin: 0;
            width: $checkboxWidth;
            height: $checkboxWidth;
            cursor: pointer;
            -moz-appearance: none;
            -webkit-appearance: none;
            -o-appearance: none;
            outline: none;
            content: none;

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: white;
                border-radius: 4px;
            }

            &:checked:after {
                content: '';
                display: block;
                width: 4px;
                height: 8px;
                border: solid $colorText;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                transform-origin: 0 0;
                position: absolute;
                top: 1px;
                left: 9px;
            }
        }

        .courier-name {
            color: $colorText;
            background-color: white;
            border-radius: 5px;
            padding: $xsmallMargin $smallMargin;
            min-width: $courierMinWidth;
        }

        .courier-pins {
            color: white;
            background-color: #FFFFFF40;
            border-radius: 5px;
            padding: $xsmallMargin;
            font-weight: 500;
            min-width: 40px;
            text-align: center;
        }
    }
}