@import '../../variables';

.map {
    width: calc(100vw - #{$sidebarWidth});
    height: calc(100vh - #{$headerHeight});
    margin-top: $headerHeight;

    .map-container {
        height: 100%;

        .marker span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $markerWidth;
            height: $markerWidth;
            color: white;
            background: black;
            border: solid 1px;
            border-radius: 0 70% 70%;
            box-shadow: 0 0 2px #00000090;
            cursor: pointer;
            transform-origin: 0 0;
            transform: rotateZ(-135deg);

            b {
                transform: rotateZ(135deg);
            }
        }

        .mapboxgl-popup-content {
            padding: $xsmallMargin;

            .popup-content span {
                font-weight: 500;
            }
        }

        .mapboxgl-ctrl {
            display: none !important;
        }
    }
}