$colorLightBlue: #4D9DE0;
$colorBlueGreen: #9EEFE5;
$colorLightGrey: #EFEFEF;
$colorDarkBlue: #3E517A;
$colorDarkLavender: #69626D;
$colorText: #161216;

$xsmallMargin: 10px;
$smallMargin: 15px;
$mediumMargin: 20px;
$bigMargin: 30px;
$xlMargin: 40px;
$xxlMargin: 50px;

$headerHeight: 80px;
$sidebarWidth: 400px;
$folderDropdownWidth: 300px;
$fileDropdownWidth: 500px;
$dropdownHeight: 50px;
$checkboxWidth: 15px;
$courierMinWidth: 260px;
$markerWidth: 30px;

.button {
    color: white;
    background-color: $colorDarkBlue;
    padding: 8px $mediumMargin;
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;

    &:hover {
        box-shadow: 0 2px 6px #00000033;
    }

    &.disabled {
        opacity: 0.6;

        &:hover {
            box-shadow: none;
        }
    }
}